

export const ENDPOINT = "https://api.brla.digital:4567/v1/business";
export const POLYGONURL = 'https://rpc-mainnet.matic.quiknode.pro/';
export const POLYGONURLB = "http/s://polygon-rpc.com/";
export const POLYGONURLC = 'https://rpc-amoy.polygon.technology/';
export const MANTEINENCETOKEN = "BRLAISTHEWAY"
export const DEFAULTCOIN = "USDC"
export const BRLA_CONTRACT_ADDRESSES = {
    1: '0x75367437Ec165ea275B64aB54DefD937107fE416',
    11155111: '0x75367437Ec165ea275B64aB54DefD937107fE416',
    137: '0xE6A537a407488807F0bbeb0038B79004f19DDDFb',
    80001: '0x658e5EA3c7690f0626aFF87cEd6FC30021A93657',
    80002: '0xA8AA3b44Afb4aA219ee12Ca4CE93C2081767BfDb',
    66: '0x459b1AC9E3cb8ecC95b7D4f92Bd5A852D9A7078c',
    65: '0x459b1AC9E3cb8ecC95b7D4f92Bd5A852D9A7078c',
  };


  export const USDC_CONTRACT_ADDRESSES = {
    1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    11155111: '',
    137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    80001: '0x0FA8781a83E46826621b3BC094Ea2A0212e71B23',
    80002: '0xB063915A4F82fE2D6b5EDDF10c1FAAEbE043a7e5',
    66: '',
    65: '',
  };

  export const USDC_NATIVE_CONTRACT_ADDRESSES = {
    1: '',
    11155111: '',
    137: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
    80001: '',
    80002: '',
    66: '',
    65: '',
  };


  export const USDT_CONTRACT_ADDRESSES = {
    1: '',
    11155111: '',
    137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    80001: '0xF829E45519804caC42376d67D054D70e64C19d29',
    80002: '0xe5C39d2242daB739859990D15853CD45bd07D4b3',
    66: '',
    65: '',
  };


  
  export const SWAP_CONTRACT_ADDRESSES = {
    1: '',
    11155111: '',
    137: '0xe41D3fcBa696B67311Dcb1c94193fb829fd6a0e2',
    80001: '0x8b82BFc48EA352f2eBd4E55E1C4CccD4fB543CFd',
    66: '',
    65: '',
  };


  
  export const SALTS = {
    1: '',
    11155111: '',
    137: '0x0000000000000000000000000000000000000000000000000000000000000089',
    80001: '0x0000000000000000000000000000000000000000000000000000000000013881',
    66: '',
    65: '',
  };


  export const CRYPTO_FRIENDLY = [
    'cyanes@brla.digital',
    'hugo@hmtrading.com',
    

  ]